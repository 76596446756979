@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
html {
	font-family: 'Roboto', sans-serif;
}
.animate-b {
	animation: bounce 0.6s infinite;
}

.animate-spin {
	animation: spin 0.8s ease;
}

.c-h {
	height: 30rem;
}

.bg-teldev {
	background-color: #133fc2;
}

.text-teldev {
	color: #133fc2;
}

.animate-slideUp {
	position: relative;
	animation: animatebottom 0.4s;
}

.animate-slideUp-header {
	position: relative;
	animation: animatebottom 0.7s;
}

.animate-slideRight {
	position: relative;
	animation: animateleft 0.4s;
}

.animate-slideLeft {
	position: relative;
	animation: animateright 0.4s;
}
@keyframes animatebottom {
	from {
		bottom: -400px;
		opacity: 0;
	}
	to {
		bottom: 0;
		opacity: 1;
	}
}

@keyframes animateright {
	from {
		right: -400px;
		opacity: 0;
	}
	to {
		right: 0;
		opacity: 1;
	}
}

@keyframes animateleft {
	from {
		left: -400px;
		opacity: 0;
	}
	to {
		left: 0;
		opacity: 1;
	}
}

@keyframes spin {
	0% {
		transform: rotateZ(0);
	}
	100% {
		transform: rotateZ(360deg);
	}
}
@keyframes bounce {
	0%,
	100% {
		transform: translateY(-25%);
		animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
	}
	50% {
		transform: translateY(0);
		animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
	}
}
